
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    // msg: String
  }
})
export default class LogoCrest extends Vue {
  // msg!: string
}
