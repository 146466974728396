import { createStore } from 'vuex'

export default createStore({
  state: {
    appVersion: "0.15.0",
    appBuilt: "2023-01-11T19:55:00.000Z",
    appLoaded: "????",
    navIsVisible: true,
  },
  mutations: {
    storeSetup (state) {
      console.log("storeSetup()")
      state.appLoaded = new Date().toISOString()
      state.navIsVisible = true
      console.log(state.appLoaded)
    },
    showNav (state) {
      state.navIsVisible = true;
    },
    hideNav (state) {
      state.navIsVisible = false;
    }
  },
  actions: {
    showTheNavBar({commit}){
      commit('showNav')
    },
    hideTheNavBar({commit}){
      commit('hideNav')
    }
  },
  modules: {
  }
})
