<template>
  <nav v-if="this.$store.state.navIsVisible" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="./assets/logo.png" width="24" height="24" class="d-inline-block align-text-top">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" class-active="active" to="/">Home</router-link>
          </li>
          
          <li class="nav-item">
            <router-link class="nav-link" class-active="active" to="/contact">Contact</router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" class-active="active" to="/privacy">Privacy</router-link>
          </li>

        </ul>

        <ul class="navbar-nav ms-auto collapse">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Users ????
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li><a class="dropdown-item" href="#">Login</a></li>
              <li><a class="dropdown-item" href="#">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <router-view/>

</template>

<style lang="scss">

#app {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.monster {
  font-size: 350pt;
}

.logo-type {
  font-family: 'Orbitron', sans-serif;
  font-weight: 400;
}

.logo-type-fat {
  font-family: 'Orbitron', sans-serif;
  font-weight: 900;
}

.lang-jp {
  font-family: 'Noto Sans JP', sans-serif;
}

footer {
  background-color: red;
}

.micawberFont {
  font-family: 'Corinthia', cursive;
  color: darkred;
}

input[type=checkbox]{
  margin-left: 1rem;
  margin-right: 1rem;
}

.app-view {
  h2 {
    margin: 2rem 5rem;
    font-weight: bold;
  }
  p {
    margin: 1rem 5rem;
  }
}

</style>
