import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

//https://stackoverflow.com/questions/65547199/using-bootstrap-5-with-vue-3
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// added the app config details for the firebase web app: "pilquiy-web-app"
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCO9HMJM5lsne_lQZwsh4Ix_ZAdXqKTXfw",
  authDomain: "pilquiy.firebaseapp.com",
  projectId: "pilquiy",
  storageBucket: "pilquiy.appspot.com",
  messagingSenderId: "415971035534",
  appId: "1:415971035534:web:78a435676bc2f6dbe8bcdf",
  measurementId: "G-6ST0DCZZ20"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

store.commit("storeSetup");

createApp(App).use(store).use(router).mount('#app')
